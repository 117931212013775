import { memoize } from 'lodash'
import { Mx8Theme } from '../../layout'
import {
  BarColors,
  BarWidths,
  DropDownProps,
  GroupedData,
  SpecificColoumnsprops,
} from './types'

export const getBarColors = (
  statTest: number | string | undefined,
  hasLowRespondents: boolean = false,
): BarColors => {
  /* c8 ignore next 7 */
  if (hasLowRespondents) {
    return {
      barColor: 'transparent',
      errorBarColor: Mx8Theme.palette.divider,
      border: Mx8Theme.palette.divider,
    }
  }
  if (
    statTest === '' ||
    (typeof statTest === 'number' && statTest >= -1 && statTest <= 1)
  ) {
    return {
      barColor: Mx8Theme.palette.secondary.main,
      errorBarColor: Mx8Theme.palette.secondary.light,
    }
  }
  if (typeof statTest === 'number' && statTest < 1) {
    return {
      barColor: Mx8Theme.palette.error.main,
      errorBarColor: Mx8Theme.palette.error.light,
    }
  } else if (typeof statTest === 'number' && statTest > 1) {
    return {
      barColor: Mx8Theme.palette.success.main,
      errorBarColor: Mx8Theme.palette.success.light,
    }
  }
  return {
    barColor: Mx8Theme.palette.success.main,
    errorBarColor: Mx8Theme.palette.success.light,
  }
}

export const getBarWidths = (
  value: number,
  error: number,
  maximumValue: number,
): BarWidths => {
  const multiplier = 0.8
  const lowValueInPercent = Math.max(
    0,
    Math.round(((value - error) / maximumValue) * 100),
  )
  const highValueInPercent = Math.min(
    100,
    Math.round(((value + error) / maximumValue) * 100),
  )
  const valueInPercent = Math.round((value / maximumValue) * 100)

  return {
    barWidth: multiplier * valueInPercent,
    errorBarWidth: multiplier * (highValueInPercent - lowValueInPercent),
    errorBarStart: multiplier * lowValueInPercent - 1,
    textStart: multiplier * highValueInPercent,
  }
}

export const getTooltipText = (
  numberRespondents: number,
  value: number,
  statTest: number | string,
  maximumValue: number,
  error?: number,
) => {
  let tool_tip = `n=${numberRespondents}`

  if (maximumValue == 1 && error != undefined) {
    const valueInPercent = Math.round(value * 100)
    const errorInPercent = Math.round(error * 100)
    tool_tip += `, Pct=${valueInPercent}%, MoE=${errorInPercent}%`
    /* c8 ignore next 3 */
  } else {
    tool_tip += `, Value=${value}`
    if (error != undefined) {
      tool_tip += `, MoE=${error}`
    }
  }

  if (typeof statTest === 'number') {
    tool_tip += `, Residual t=${statTest.toFixed(2)}`
  } else if (statTest && statTest.length > 0) {
    tool_tip += `, Higher than ${statTest}`
  }

  return tool_tip
}

// eslint-disable-next-line
export const compareArrays = (array1: DropDownProps, array2: any[]) => {
  return array1?.[0]?.data?.flatMap((obj1: { key?: string }) => {
    const dynamicKey = obj1?.key // Extract the dynamic key from obj1, like 'Gender-man' or 'Gender-woman'
    // Use filter to get all objects from array2 that have the dynamic key
    if (dynamicKey) {
      const matchedObjects = array2.filter((obj2) => dynamicKey in obj2)
      return matchedObjects.map((matchedObj) => matchedObj[dynamicKey])
    }
    // If matches are found, return an array of matched objects
  })
}

export const specificColumnData = (result: SpecificColoumnsprops[]) => {
  const retrunData = groupByKey(result)?.map(
    (item: { label: string; data: SpecificColoumnsprops[] }) => {
      const labelTrim = item.label.split('-')[1]?.trim()
      return {
        label: labelTrim,
        id: labelTrim,
        data: item?.data?.map((child) => child?.val),
        /* c8 ignore next 6 */
        valueFormatter: (v: unknown, { dataIndex }: { dataIndex: number }) => {
          const { val, n, err, stat_test } = item.data[dataIndex]
          return `n= ${n}, value=${val}, MoE=${err}, Residual t=${stat_test}`
        },
      }
    },
  )

  return retrunData
}

export const groupByKey = (array: SpecificColoumnsprops[]) => {
  return array?.reduce((acc: GroupedData[], obj: SpecificColoumnsprops) => {
    // Remove text before hyphen in 'key' to create a grouping 'label'
    const compareLabel = obj.key // Extract label after the hyphen

    // Check if the group for this 'compareLabel' already exists
    let group = acc.find((group) => group.label === compareLabel)

    if (!group) {
      // If the group doesn't exist, create it with the original label
      group = { label: obj.key, data: [] }
      acc.push(group)
    }

    // Add the current 'val' to the group's 'data' array
    group.data.push({ ...obj })

    return acc
  }, [])
}

// eslint-disable-next-line
export const totalData = (rows: any) => {
  const returnData = {
    label: 'Total',
    id: 'Total',
    baseline: rows[0]?.['total-total']?.val,
    area: true,
    data: rows?.map((item: { 'total-total': { val: string } }) =>
      item?.['total-total'] ? item?.['total-total'].val : '',
    ),
    valueFormatter: (v: string, { dataIndex }: { dataIndex: number }) => {
      if (rows[dataIndex]['total-total']) {
        const { val, n, err, stat_test } = rows[dataIndex]['total-total']
        return `n= ${n}, value=${val}, MoE=${err}, Residual t=${stat_test}`
      }
    },
  }
  return returnData
}

/**
 * Helper function to get the width of a string
 * @param copy - the string to get the width of
 * @param padding - the padding to add to the width
 * @returns a number representing the width of the string
 */
export const getStringWidth = memoize(
  (copy: string, padding: number = 20): number => {
    const MINIMUM_WIDTH = 75

    const element = document.createElement('canvas')
    const context = element.getContext('2d')

    /* c8 ignore next 4 */
    if (context) {
      context.font = '13pt Roboto Light'
      return context.measureText(copy).width + padding
    }
    return Math.max(MINIMUM_WIDTH, copy.length * 10 + padding)
  },
)
