import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

const ReportSkeleton = () => {
  return (
    <TableContainer component={Paper} sx={{ padding: 5 }}>
      <Skeleton width={'99%'} height={50} sx={{ marginX: 2 }} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton width={'100%'} height={35} />
            </TableCell>
            <TableCell>
              <Skeleton width={'80%'} height={35} />
            </TableCell>
            <TableCell>
              <Skeleton width={'100%'} height={35} />
            </TableCell>
            <TableCell>
              <Skeleton width={'100%'} height={35} />
            </TableCell>
            <TableCell>
              <Skeleton width={'100%'} height={35} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(6)).map((_, index) => {
            const dynamicWidth = index % 2 === 0 ? '30%' : '70%'
            return (
              <TableRow key={`table-body${index + 1}`}>
                <TableCell>
                  <Skeleton width={'100%'} height={35} />
                </TableCell>
                <TableCell>
                  <Skeleton width={'80%'} height={35} />
                </TableCell>
                <TableCell>
                  <Skeleton width={dynamicWidth} height={35} />
                </TableCell>
                <TableCell>
                  <Skeleton width={dynamicWidth} height={35} />
                </TableCell>
                <TableCell>
                  <Skeleton width={dynamicWidth} height={35} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportSkeleton
