import { SxProps, Theme } from '@mui/material'
import { Mx8Theme } from '../../../layout/themes'

export const summaryCard: SxProps<Theme> = {
  height: '100%',
  width: '100%',
  padding: '16px',
  border: '1px solid',
  borderColor: 'primary.lightDarkColor',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'background.paper',
}

export const descriptionBox: SxProps = {
  marginTop: 1.5,
  '& .RaLabeled-label': {
    display: 'none',
  },

  '& .toastui-editor-ww-container>.toastui-editor': {
    backgroundColor: 'background.paper',
  },
  '& .toastui-editor-defaultUI-toolbar': {
    backgroundColor: Mx8Theme.palette.divider,
  },
  '& .toastui-editor-defaultUI-toolbar button': {
    borderColor: Mx8Theme.palette.divider,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '& .toastui-editor-defaultUI-toolbar button:not(:disabled):hover': {
    backgroundColor: 'background.paper',
    borderColor: 'background.paper',
  },
}

export const audienceCreateReWeightStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 1,
  fontSize: '15px',
  '&  .MuiButton-text': {
    fontSize: '13px',
  },
}
export const SurveyEditButton = (isActive?: boolean) => {
  return {
    border: 1,
    borderColor: '#DBD2C8',
    backgroundColor: isActive ? '#D7D0C7' : 'background.paper',
    color: '#317CFE',
    marginRight: '5px',
    borderRadius: 0,
    marginBottom: '5px',
    padding: '5px',
  }
}

export const SurveyIcon: SxProps<Theme> = {
  fontSize: '1rem',
  color: 'black',
}
