import { Admin, Resource, CustomRoutes } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import ReactGA from 'react-ga4'
import { useEffect, useState } from 'react'
import { getAuth } from './Providers/AuthProvider'
import { getDataProvider } from './Providers/DataProvider'
import { Mx8DarkTheme, Mx8Layout, Mx8Theme } from './layout'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { LicenseInfo } from '@mui/x-license'
import { Box } from '@mui/material'
import {
  Accounts,
  Audiences,
  Datasets,
  Insights,
  Projects,
  Reports,
  Subscription,
  Surveys,
  Translation,
} from './pages'
import { loader } from '@monaco-editor/react'
import NotFound from './pages/NotFound/NotFound'
import ProjectNotFound from './pages/NotFound/ProjectNotFound'
import SurveyNotFound from './pages/NotFound/SurveyNotFound'
LicenseInfo.setLicenseKey(
  '829c5bc522d1bab7275af0a40b248c8dTz05NjYzMSxFPTE3NTU4NTg0OTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
)

import * as Sentry from '@sentry/react'
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID as string
enum AuthState {
  Loading,
  HandlingCallback,
  Authenticated,
  Unauthenticated,
}

declare global {
  interface Window {
    requestError?: {
      status: number
      message: string
      resource: string
      operation: string
    }
  }
}

const Mx8Admin = () => {
  const auth0 = getAuth()
  const [loginState, setLoginState] = useState(AuthState.Loading)
  const intercom = useIntercom()

  const AuthFunc = async () => {
    try {
      const isAuthenticated = await auth0.auth0Client.isAuthenticated()
      if (window.location.href.includes('auth-callback')) {
        setLoginState(AuthState.HandlingCallback)
      } else if (isAuthenticated) {
        // set the intercom user
        const user = await getAuth().auth0Client.getUser()
        if (user?.intercomHash) {
          intercom.boot({
            name: user?.given_name,
            email: user?.email,
            userHash: user?.intercomHash,
          })
        }
        const superBoolean = await getAuth().getPermissions()

        sessionStorage.setItem(
          'super-user',
          JSON.stringify(superBoolean.is_superuser),
        )
        setLoginState(
          isAuthenticated ? AuthState.Authenticated : AuthState.Unauthenticated,
        )
      }
    } catch (error) {
      auth0.auth0Client.loginWithRedirect()
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    AuthFunc()
    ReactGA.initialize(`${import.meta?.env?.VITE_GA4_MEASUREMENT_ID}`)
    loader.config({
      paths: {
        vs: '/monaco',
      },
    })
  }, [])

  return (
    <BrowserRouter basename="/">
      {loginState == AuthState.Loading ||
      loginState == AuthState.HandlingCallback ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: Mx8Theme.palette.background.default,
          }}
        >
          <CircularProgress sx={{ color: Mx8Theme.palette.primary.main }} />
        </Box>
      ) : null}
      <div
        style={{
          // Hide the app until the user is authenticated
          display: loginState == AuthState.Authenticated ? 'block' : 'none',
        }}
      >
        <Admin
          dataProvider={getDataProvider(auth0.httpClient)}
          layout={Mx8Layout}
          authProvider={auth0.authProvider}
          theme={Mx8Theme}
          darkTheme={Mx8DarkTheme}
          defaultTheme="light"
          loginPage={false}
          catchAll={NotFound}
        >
          <Resource name="projects" {...Projects} />
          <Resource name="surveys" {...Surveys} />
          <Resource name="audiences" {...Audiences} />
          <Resource name="reports" {...Reports} />
          <Resource name="accounts" {...Accounts} />
          <Resource name="insights" {...Insights} />
          <Resource name="translations" {...Translation} />
          <Resource name="datasets" {...Datasets} />
          <Resource name="superusers" {...Subscription} />
          <CustomRoutes>
            <Route path="*" element={<NotFound />} />
            <Route path="project-not-found" element={<ProjectNotFound />} />
            <Route path="survey-not-found" element={<SurveyNotFound />} />
          </CustomRoutes>
        </Admin>
      </div>
    </BrowserRouter>
  )
}

export const App = () => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Mx8Admin />
    </IntercomProvider>
  )
}
