import { useState } from 'react'
import { Identifier, useGetOne } from 'react-admin'
import { ErrorType } from '../pages/Reports/types'
import * as Sentry from '@sentry/react'

export const useGetOneReport = (
  id: Identifier | undefined,
  dataset: string,
  audience: boolean = false,
) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { data, isLoading, error, refetch } = useGetOne(
    /* c8 ignore next 1 */
    audience ? `audience/report` : `reports/${dataset}`,
    { id: id },
    {
      enabled: !!dataset,
      /* c8 ignore next 14 */
      onError: (err: unknown) => {
        const error = err as ErrorType
        const errorMsg = () => {
          if (typeof error.body === 'string') {
            return error.body
          } else if (typeof error.body.errors === 'string') {
            return error.body.errors
          } else {
            return error?.body?.errors?.message
          }
        }
        setErrorMessage(errorMsg())
        Sentry.captureException(errorMsg())
      },
      retry: 1,
    },
  )

  /* c8 ignore next 6 */
  if (data && !isLoading && !error && data?.cross_tab?.status == 'pending') {
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  return { data, isLoading, error, errorMessage }
}
