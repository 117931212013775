import { Paper, Skeleton } from '@mui/material'

const UpdateCodeSkeleton = () => {
  return (
    <Paper
      elevation={3}
      sx={{ padding: 4, borderRadius: 2, backgroundColor: '#f5f5f5' }}
    >
      <Skeleton
        variant="text"
        width={150}
        height={20}
        sx={{ marginBottom: 1 }}
      />
      <Skeleton
        variant="text"
        width={250}
        height={20}
        sx={{ marginBottom: 1 }}
      />
      <Skeleton
        variant="rectangular"
        width={350}
        height={80}
        sx={{ borderRadius: 1 }}
      />
    </Paper>
  )
}
export default UpdateCodeSkeleton
