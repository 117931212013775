import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SurveyNotFound = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h1" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Survey Says… Error! 📊❌
      </Typography>
      <Typography variant="body1" color="textSecondary" maxWidth="600px">
        We crunched the numbers, and, well, they crunched back. Try refreshing
        or checking back later!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/projects')}
        sx={{ mt: 2 }}
      >
        Back to Home
      </Button>
    </Box>
  )
}

export default SurveyNotFound
