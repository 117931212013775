import { Alert, Grid2 } from '@mui/material'
import { useState } from 'react'
import AnalysisContainer from './AnalysisContainer/AnalysisContainer'
import SignificanceInput from './SignificanceInput'
import ConfidenceInput from './ConfidenceInput'
import { useGetList, useRecordContext } from 'ra-core'
import { ReportAnalysisProps } from './types'
import { BooleanInput, NumberInput } from 'react-admin'
import { ReportConfigurationSekeleton } from '../../../components/Skeletons'

const ReportAnalysis = ({ subscription, source }: ReportAnalysisProps) => {
  const [validationForReportQuestion, setValidationForReportQuestion] =
    useState(false)
  const record = useRecordContext()
  const { data } = useGetList('report_questions', {
    /* c8 ignore next 2 */
    filter: { id: record?.selected_questions, report_id: record?.id },
    sort: { field: 'sort_order', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  })
  if (!data) {
    return (
      <Grid2
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          minHeight: '200px',
        }}
      >
        <ReportConfigurationSekeleton />
      </Grid2>
    )
  }

  return (
    <Grid2 container width="100%" spacing={2} data-testid="report-analysis">
      <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid2 size={{ xs: 3 }}>
          <SignificanceInput />
        </Grid2>
        <Grid2 size={{ xs: 3 }} paddingLeft={2}>
          <ConfidenceInput />
        </Grid2>
        <Grid2 size={{ xs: 3 }} paddingLeft={2}>
          <NumberInput source="minimum_respondents" fullWidth min={1} />
        </Grid2>
        <Grid2 size={{ xs: 3 }} paddingLeft={2}>
          {
            /* c8 ignore next 5 */ source === 'project-report' && (
              <BooleanInput
                source="consistent_respondent_ids"
                label="Consistent Respondent"
              />
            )
          }
        </Grid2>
      </Grid2>
      {
        /* c8 ignore next 6 */
        validationForReportQuestion && (
          <Alert severity="error">
            At least one 'Row' report style is required, and no more than one
            'Page' report style can be selected.
          </Alert>
        )
      }

      <AnalysisContainer
        reference="report_questions"
        title="Question"
        subscription={subscription}
        showOption={true}
        name="reporting_label"
        setValidationForReportQuestion={setValidationForReportQuestion}
        nestedKey="@@ra-many/reports/report_questions/report_id"
      />
      <AnalysisContainer
        name="topic"
        reference="report_topics"
        title="Topic"
        isNoneOption
        setValidationForReportQuestion={setValidationForReportQuestion}
        nestedKey="@@ra-many/reports/report_topics/report_id"
      />
    </Grid2>
  )
}

export default ReportAnalysis
