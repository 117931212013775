import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const ReportConfigurationSekeleton = () => {
  return (
    <Box sx={{ padding: 1, width: '100%' }}>
      <Box display="flex" gap={2} mb={2}>
        <Skeleton variant="rectangular" width={200} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </Box>

      <h3>
        <Skeleton width={150} />
      </h3>
      {[1, 2, 3].map((_, index) => (
        <Box
          key={`${index + 1}`}
          display="flex"
          alignItems="center"
          gap={2}
          mb={1}
        >
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="circular" width={30} height={30} />
        </Box>
      ))}
      <Skeleton variant="rectangular" width={150} height={40} />
    </Box>
  )
}

export default ReportConfigurationSekeleton
