import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h1" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Oops! We Lost Our Train of Thought 🚂💨
      </Typography>
      <Typography variant="body1" color="textSecondary" maxWidth="600px">
        Something went wrong, and we lost track of your request. Give it another
        go, and we'll try to stay on track!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/projects')}
        sx={{ mt: 2 }}
      >
        Back to Home
      </Button>
    </Box>
  )
}

export default NotFound
