import { memo } from 'react'
import { Box, Tooltip } from '@mui/material'
import { getBarColors, getBarWidths, getTooltipText } from '../../utils'
import { ReportingBarProps } from '../../types'
import {
  annotationStyle,
  barContainer,
  barStyle,
  errorBarEnd,
  errorBarStartStyle,
  errorBarStyle,
  textContainer,
} from './styles'
import { useStore } from 'ra-core'

const ReportingBar = memo(function ReportingBar(props: ReportingBarProps) {
  const { numberRespondents, value, error, statTest, maximumValue } = props
  const [minimumRespondent] = useStore('minimum_respondents')

  // Calculate the width of the bars
  /* c8 ignore next 2 */
  const barWidths = getBarWidths(value, error ?? 0, maximumValue)
  const hasLowRespondents = numberRespondents < minimumRespondent

  const {
    barColor: firstColor,
    errorBarColor: secondColor,
    border: borderColor,
  } = getBarColors(statTest, hasLowRespondents)

  const anotaionType = typeof statTest === 'string'
  return (
    <Tooltip
      arrow
      title={getTooltipText(
        numberRespondents,
        value,
        statTest,
        maximumValue,
        error,
      )}
    >
      <Box sx={barContainer}>
        {value > 0 && [
          <Box
            title="bar"
            key="bar"
            sx={barStyle(barWidths.barWidth, firstColor, borderColor)}
          />,
          <Box
            title="annotation"
            key="annotation"
            sx={annotationStyle(barWidths.barWidth)}
          >
            {anotaionType && statTest}
          </Box>,
          <Box
            title="error_bar_start"
            key="error_bar_start"
            sx={errorBarStartStyle(barWidths.errorBarStart, secondColor)}
          />,
          <Box
            title="error_bar"
            key="error_bar"
            sx={errorBarStyle(
              barWidths.errorBarStart,
              barWidths.errorBarWidth,
              secondColor,
            )}
          />,
          <Box
            title="error_bar_end"
            key="error_bar_end"
            sx={errorBarEnd(
              barWidths.errorBarStart + barWidths.errorBarWidth,
              secondColor,
            )}
          />,
        ]}
        <Box sx={textContainer(barWidths.textStart)}>
          {/* c8 ignore next */}
          {maximumValue == 100 ? value + '%' : value}
        </Box>
      </Box>
    </Tooltip>
  )
})

export default ReportingBar
