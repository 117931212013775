import React from 'react'
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { CustomFormActionContainer } from './style'
import { FieldValues, useFormContext } from 'react-hook-form'
import { FormActionProps } from '../utils/types'

export const FormActionContainer: React.FC<FormActionProps> = (props) => {
  const {
    setEditInDialogOpen,
    setCurrentReportId,
    reports_list,
    currentReportId,
    source,
    editInDialogOpen,
    setPostData,
    isSurveyCreating,
  } = props
  const { getValues } = useFormContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const redirect = useRedirect()
  /* c8 ignore next 18 */
  const saveFunction = async () => {
    if (source == 'survey-create' && setPostData) {
      const formData = getValues()
      const descriptionData: FieldValues = {
        name: formData.name,
        audience: formData.audience,
        description: formData.description,
        project_id: formData.project_id,
        language: formData.language,
        estimated_ir: formData.estimated_ir,
        code: null,
      }
      if (formData?.description?.length > 0) {
        setPostData(descriptionData)
      }
    }
    localStorage.removeItem('code')
  }
  /* c8 ignore next 5 */
  const saveDataTestId = editInDialogOpen ? `dialog-save-button` : `save-button`
  const deleteDataTestId = editInDialogOpen
    ? `dialog-delete-button`
    : `delete-button`
  return (
    <Toolbar sx={CustomFormActionContainer(!!editInDialogOpen)}>
      <SaveButton
        id="save_button"
        sx={{ fontSize: 13 }}
        /* c8 ignore next 2 */
        onClick={() => saveFunction()}
        data-testid={saveDataTestId}
        disabled={isSurveyCreating}
      />
      <WithRecord
        render={(data) => {
          return (
            <DeleteButton
              disabled={props?.source == 'project-edit'}
              mutationOptions={{
                /* c8 ignore next 26 */
                onSuccess: () => {
                  if (
                    setEditInDialogOpen &&
                    setCurrentReportId &&
                    currentReportId &&
                    reports_list &&
                    reports_list?.length > 0
                  ) {
                    const newReportList = reports_list.filter(
                      (report) => report.id !== currentReportId,
                    )
                    setCurrentReportId(Number(newReportList[0]?.id))
                    notify(`${source}  deleted successfully`, {
                      type: 'success',
                      undoable: false,
                    })
                    refresh()
                    setEditInDialogOpen(false)
                  } else if (props?.source == 'Audience') {
                    redirect('edit', `surveys/${data?.survey_id}`)
                    notify(`Audience ${data?.id} Deleted successfully`, {
                      type: 'success',
                      undoable: false,
                    })
                  }
                },
              }}
              data-testid={deleteDataTestId}
              sx={{ fontSize: 13 }}
            />
          )
        }}
      />
    </Toolbar>
  )
}
