import { SxProps } from '@mui/system'

export const projectTable: SxProps = {
  width: '100%',
  padding: '8px 10px 0',
  border: '1px solid',
  borderColor: 'primary.lightDarkColor',
  backgroundColor: 'background.paper',
}

export const userTable: SxProps = {
  backgroundColor: 'background.paper',
  '& .MuiTableRow-head .MuiTableCell-root': {
    fontWeight: 600,
  },
  '& .MuiTableCell-root': {
    padding: '7px 20px',
  },
}
