import { SxProps, Theme } from '@mui/material'
import { Mx8Theme } from '../../../layout'

export const ButtonStyle = {
  textTransform: 'none',
  fontWeight: '600',
  padding: '3px 25px 2px 25px',
  fontSize: 13,
  backgroundcolor: Mx8Theme.palette.primary.main,
  '&:hover': {
    backgroundcolor: Mx8Theme.palette.primary.main,
  },
}

/* c8 ignore next 16 */
export const insightsContainer = (
  height?: string,
  isSurvey?: boolean,
): SxProps<Theme> => ({
  height,
  padding: 2,
  overflow: 'auto',
  ...(isSurvey
    ? {
        backgroundColor: 'background.paper',
        boxShadow: 'none',
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }
    : {}),
})
