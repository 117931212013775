import { DataProvider } from 'react-admin'
import { CopyableReport } from '../components/types'

export const fetchReports = async (
  dataProvider: DataProvider,
  sourceSurveyId?: string,
  targetSurveyId?: string,
) => {
  if (!sourceSurveyId || !targetSurveyId) return []
  /* c8 ignore next 4 */
  const API_BASE_URL = `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${
    import.meta.env.VITE_MX8_ADMIN_API_DOMAIN
  }`
  const response = await dataProvider.getCopyList(
    `${API_BASE_URL}/v1/surveys/${sourceSurveyId}/copy_reports/${targetSurveyId}`,
    {
      options: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  )
  return response.data as CopyableReport[]
}
