import {
  AutoFixHighOutlined,
  ContentCopyOutlined,
  ContentCutOutlined,
  ContentPasteOutlined,
  FindReplaceOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  RedoOutlined,
  SearchOutlined,
  UndoOutlined,
} from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { SurveyEditButton, SurveyIcon } from '../../Edit/styles'
import { CodeEditorToolBarProps } from '../../types'
import CodeLockUnlock from './CodeLockUnLock/CodeLockUnlock'
const CodeEditorToolBar = (props: CodeEditorToolBarProps) => {
  const { instanceEditor, setCopilotState, editorFromCreate, copilotState } =
    props
  const triggerFunction = async (command: string) => {
    /* c8 ignore start */
    if (!instanceEditor) return
    instanceEditor.focus()
    const selection = instanceEditor?.getSelection()
    if (!selection) return
    const selectedText = instanceEditor?.getModel()?.getValueInRange(selection)
    if (command == 'copy') {
      if (selectedText) {
        await navigator.clipboard.writeText(selectedText)
      }
      // * for cut command
    } else if (command == 'cut') {
      if (selectedText) {
        await navigator.clipboard.writeText(selectedText)
        if (selection) {
          instanceEditor?.executeEdits('cut', [{ range: selection, text: '' }]) //* Remove text after cut
        }
      }
    }
    /* c8 ignore end */
    if (command == 'paste') {
      const clipboardText = await navigator.clipboard.readText()
      if (selection) {
        instanceEditor?.executeEdits('paste', [
          {
            range: selection,
            text: clipboardText,
            forceMoveMarkers: true,
          },
        ])
      }
      // * for other commands
    } else {
      instanceEditor.trigger('keyboard', command, null)
    }
  }

  const handleCopilotToggle = () => {
    if (setCopilotState) {
      setCopilotState((prev) => ({ ...prev, showInput: !prev.showInput }))
    }
  }
  const codeReadOnly = () => {
    if (setCopilotState) {
      setCopilotState((prev) => ({ ...prev, isCodeLock: !prev.isCodeLock }))
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'space-around',
        maxWidth: '50%',
      }}
    >
      {/* Undo/Redo */}
      <Tooltip title="Undo">
        <IconButton
          data-testid="icon-btn-1"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('undo')}
        >
          <UndoOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Redo">
        <IconButton
          data-testid="icon-btn-2"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('redo')}
        >
          <RedoOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      {/* Copy/Cut/Paste */}
      <Tooltip title="Copy">
        <IconButton
          data-testid="icon-btn-3"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('editor.action.clipboardCopyAction')}
        >
          <ContentCopyOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Paste">
        <IconButton
          data-testid="icon-btn-4"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('paste')}
        >
          <ContentPasteOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Cut">
        <IconButton
          data-testid="icon-btn-5"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('editor.action.clipboardCutAction')}
        >
          <ContentCutOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      {/* Find/Replace */}
      <Tooltip title="Find">
        <IconButton
          data-testid="icon-btn-6"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('actions.find')}
        >
          <SearchOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Replace">
        <IconButton
          data-testid="icon-btn-7"
          sx={SurveyEditButton()}
          onClick={() =>
            triggerFunction('editor.action.startFindReplaceAction')
          }
        >
          <FindReplaceOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      {/* Indent/Outdent */}
      <Tooltip title="Indent">
        <IconButton
          data-testid="icon-btn-8"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('editor.action.indentLines')}
        >
          <FormatIndentIncreaseOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Outdent">
        <IconButton
          data-testid="icon-btn-9"
          sx={SurveyEditButton()}
          onClick={() => triggerFunction('editor.action.outdentLines')}
        >
          <FormatIndentDecreaseOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      {/* Copilot */}
      <Tooltip title="Copilot">
        <IconButton
          data-testid="icon-btn-10"
          sx={SurveyEditButton(copilotState?.showInput)}
          onClick={() => handleCopilotToggle()}
          disabled={editorFromCreate}
        >
          <AutoFixHighOutlined sx={SurveyIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Lock/Unlock">
        <CodeLockUnlock
          codeReadOnly={() => codeReadOnly()}
          source="survey_code_is_locked"
          disabled={editorFromCreate}
          isCodeLock={copilotState?.isCodeLock}
        />
      </Tooltip>
    </Box>
  )
}

export default CodeEditorToolBar
