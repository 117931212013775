import { Skeleton, Box } from '@mui/material'

const LatestInsightSkeleton = () => {
  return (
    <Box sx={{ py: 2, px: 2 }}>
      {/* Title Skeleton */}
      <Skeleton variant="text" width={150} height={32} sx={{ mb: 2 }} />

      {/* No insights message */}
      <Skeleton variant="text" width={120} height={20} sx={{ mb: 2 }} />

      {/* "Did you know..." text */}
      <Skeleton variant="text" width={100} height={22} sx={{ mb: 1 }} />

      {/* Placeholder for Markdown content */}
      <Skeleton variant="rectangular" height={80} width="100%" />
      <Skeleton variant="text" width="100%" height={22} sx={{ mt: 1 }} />
      <Skeleton variant="text" width="100%" height={22} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="100%" height={22} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="100%" height={22} sx={{ mb: 1 }} />
    </Box>
  )
}

export default LatestInsightSkeleton
