import { Button } from '@mui/material'
import { CopilotCommonButtonProps } from './types'
import { useEffect, useRef } from 'react'
import { CopilotCommonButtonStyle } from './style'
const CopilotCommonButton = (props: CopilotCommonButtonProps) => {
  const { Icone, title, onClick, disabled } = props
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    if (!disabled) {
      setTimeout(() => {
        /* c8 ignore next 3 */
        if (buttonRef.current && buttonRef.current.innerText === 'APPLY') {
          buttonRef.current.focus()
        }
      }, 500)
    }
  }, [disabled])

  return (
    <Button
      data-testid={`copilot-common-${title}-button`}
      ref={buttonRef}
      disabled={disabled}
      variant="outlined"
      startIcon={Icone}
      onClick={() => onClick()}
      sx={{ CopilotCommonButtonStyle }}
    >
      {title}
    </Button>
  )
}
export default CopilotCommonButton
