import {
  Box,
  CircularProgress,
  Grid2,
  MenuItem,
  Select,
  Backdrop,
  SelectChangeEvent,
} from '@mui/material'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import {
  Button,
  DeleteButton,
  Labeled,
  RaRecord,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
  useStore,
  WithRecord,
} from 'react-admin'
import { useEffect, useState } from 'react'
import { Analytics, Settings, ViewList } from '@mui/icons-material'
import ReportEditDialog from '../pages/Reports/components/ReportEditDialog'
import { Mx8Theme } from '../layout'
import { CrossTabPager } from '../utils/types'
import FileDownload from './FileDownload/FileDownload'
import { ReportingDatasetSelect, CrossTabPageSelect } from '.'
import { NavigationProps } from './types'
import CopyReportsDialog from './CopyReportsDialog'
import ProjectConfigureDialog from './ProjectConfigureDialog'
import { useLocation, useNavigate } from 'react-router'

const CommonReportNavigator = (props: NavigationProps) => {
  const {
    ReportElement,
    subscription = undefined,
    enabaleFetch,
    disbaleFetch,
    currentReportId,
    setCurrentReportId,
    source,
    reportType,
    setCurrentDataset,
  } = props
  const refresh = useRefresh()
  const value = `No ${reportType}s available`
  const reports_list = props.reportList
  const [editInDialogOpen, setEditInDialogOpen] = useState(false)
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false)
  const [openProjectConfigure, setOpenProjectConfigure] = useState(false)
  const [copyLoader, setCopyLoader] = useState(false)
  const notify = useNotify()
  const location = useLocation()
  const navigate = useNavigate()
  const record = useRecordContext()
  // Setup paging
  const [currentPage, setCurrentPage] = useStore(
    `crossTabPage-${source}-${props.uniqueId}`,
  )
  const [latestReportId] = useStore(`current-survey-report-${record?.id}`)
  const [latestProjectReportId] = useStore(
    `current-project-report-${record?.id}`,
  )
  const [currentReportDataset] = useStore(
    `current-survey-report-dataset-${record?.id}`,
  )
  const [pageData, setPageData] = useState<CrossTabPager>()
  const [selectedReportNumber, selectedReportType] = location.hash
    .substring(1)
    .split('/')
  const [isManualChange, setIsManualChange] = useState(false)
  const hasReport =
    reports_list?.length && reports_list?.length > 0 && !props.disabled
  const isReportTab = location.pathname.includes('reports')
  const dynamicReportId =
    source === 'project-report' ? latestProjectReportId : latestReportId

  const { data: reportInsightDataSetData } = useGetOne(
    `reports/${props.currentDataset}`,
    {
      id: currentReportId,
    },
    {
      enabled:
        !!currentReportId &&
        isReportTab &&
        !!props.currentDataset &&
        reports_list &&
        reports_list?.length > 0,
    },
  )

  const isReportDataLoading =
    reportInsightDataSetData?.cross_tab?.status === 'pending' ||
    reportInsightDataSetData?.cross_tab?.status === 'error'

  useEffect(() => {
    if (isReportTab) {
      /* c8 ignore next 15 */
      if (!hasReport) {
        handleNoReport()
      } else if (
        reports_list &&
        reports_list?.length > 0 &&
        !isManualChange &&
        !(selectedReportNumber && selectedReportType)
      ) {
        handleReportSelection()
      } else if (selectedReportNumber && selectedReportType) {
        handleExistingURL()
      } else if (currentReportId && props.currentDataset) {
        handleValidParams()
      }
    }
    setIsManualChange(false)
  }, [
    reports_list,
    dynamicReportId,
    selectedReportNumber,
    selectedReportType,
    isReportTab,
    hasReport,
  ])

  useEffect(() => {
    if (isReportTab && selectedReportNumber && selectedReportType) {
      setIsManualChange(true)
      handleExistingURL()
    }
  }, [selectedReportNumber, selectedReportType])

  const handleNoReport = () => {
    navigate(location.pathname, { replace: true })
  }

  /* c8 ignore next 18 */
  const handleReportSelection = () => {
    const defaultDataset = 'live'
    const selectedReport = dynamicReportId
      ? reports_list?.find((report: RaRecord) => report?.id === dynamicReportId)
      : reports_list?.[0]
    const datasets = selectedReport?.datasets ?? {}
    const liveIncluded = Object.keys(datasets).includes(defaultDataset)
    const dataset = liveIncluded
      ? defaultDataset
      : (Object.keys(datasets)[0] ?? defaultDataset)

    navigate(`#${selectedReport?.id}/${dataset}`, {
      replace: true,
    })
    setCurrentReportId(parseInt(selectedReport?.id ?? dynamicReportId))
    setCurrentDataset(dataset)
  }

  const handleExistingURL = () => {
    const isNumber = !!parseInt(selectedReportType)
    /* c8 ignore next 1 */
    const dataset = isNumber ? currentReportDataset : selectedReportType

    const inReport = reports_list?.find(
      (report: RaRecord) => report.id === parseInt(selectedReportNumber),
    )

    /* c8 ignore next 16 */
    if (inReport) {
      navigate(`#${selectedReportNumber}/${dataset}`, {
        replace: true,
      })
      setCurrentReportId(parseInt(selectedReportNumber))
      setCurrentDataset(dataset)
    } else if (latestReportId) {
      navigate(`#${latestReportId}/${props.currentDataset}`, {
        replace: true,
      })
      setCurrentReportId(parseInt(latestReportId))
      setCurrentDataset(props.currentDataset)
    } else {
      handleReportSelection()
    }
  }

  /* c8 ignore next 6 */
  const handleValidParams = () => {
    navigate(`#${currentReportId}/${props.currentDataset}`, {
      replace: true,
    })
  }
  /* c8 ignore next 7 */
  const handleReportChange = (event: SelectChangeEvent<number>) => {
    setIsManualChange(true)
    navigate(`#${event.target.value}/${props.currentDataset}`, {
      replace: true,
    })
    setCurrentReportId(parseInt(event.target.value.toString()))
  }

  const getDatasets = () => {
    if (reportInsightDataSetData) {
      return {
        datasets: reportInsightDataSetData?.datasets,
        shouldShowLoading: isReportDataLoading,
      }
    } else {
      return {}
    }
  }

  /* c8 ignore next 16 */
  const handleMutations = {
    onSuccess: (record: RaRecord) => {
      setIsManualChange(true)
      navigate(`#${parseInt(record.id.toString())}/${props.currentDataset}`, {
        replace: true,
      })
      setCurrentReportId(parseInt(record.id.toString()))
      notify(`${props?.reportType} created successfully`, {
        type: 'success',
        undoable: false,
      })
      refresh()
      props?.reportType == 'report' && setEditInDialogOpen(true)
    },
  }
  const isFromProject = source === 'project-report'
  const EditElement = props.EditElement
  const AnalysisElement = props.AnalysisElement

  return (
    <Grid2 width="100%" container spacing={2}>
      <Grid2 size={{ md: 5, xs: 12 }}>
        <Labeled fullWidth>
          {hasReport ? (
            <Select
              data-testid={`${reportType}-selector`}
              size="small"
              fullWidth
              label={props.reportLabel}
              value={currentReportId ?? 0}
              onChange={handleReportChange}
            >
              {reports_list.map((report: RaRecord) => {
                return (
                  <MenuItem
                    data-testid={`${reportType}-selector-${report.id}`}
                    key={report.id}
                    value={report.id}
                  >
                    {report[props.reportNameField]}
                  </MenuItem>
                )
              })}
            </Select>
          ) : (
            <Select
              data-testid={`${reportType}-selector`}
              label={props.reportLabel}
              size="small"
              value={value}
              fullWidth
              disabled
            >
              <MenuItem
                key={value}
                data-testid={`${reportType}-selector-${value}`}
                value={value}
              >
                {value}
              </MenuItem>
            </Select>
          )}
        </Labeled>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ display: 'flex', justifyContent: 'start' }}
      >
        <Box
          display="flex"
          justifyContent="start"
          flexDirection="row"
          flexWrap={`wrap`}
          gap="5px"
          sx={{ marginTop: { sm: '20px', xs: null } }}
        >
          {hasReport ? (
            <>
              <Button
                aria-label={`Questions ${reportType}`}
                /* c8 ignore next 3 */
                onClick={() => {
                  setEditInDialogOpen(true)
                }}
                label="Questions"
                variant="text"
              >
                <ViewList />
              </Button>

              {AnalysisElement && (
                <Button
                  aria-label={`Analysis ${reportType}`}
                  label="Analysis"
                  variant="text"
                  onClick={() => setOpenAnalysisModal(true)}
                  data-testid="analysis-open-btn"
                >
                  <Analytics />
                </Button>
              )}
              <FileDownload
                source=""
                type="report"
                dataset={props.currentDataset}
                reportId={currentReportId}
                variant="text"
                sx={{
                  margin: 0,
                  fontSize: '13px',
                  color: Mx8Theme.palette.primary.main,
                  fontWeight: 600,
                }}
                enabaleFetch={enabaleFetch}
                disbaleFetch={disbaleFetch}
              />
              <ReportEditDialog
                currentReportId={currentReportId}
                isOpen={editInDialogOpen}
                reportList={props.reportList}
                reportResource={props.reportResource}
                reportType={reportType}
                setCurrentReportId={setCurrentReportId}
                setIsOpen={setEditInDialogOpen}
                title={'Report Questions'}
                setAnalysisOpen={setOpenAnalysisModal}
              >
                <EditElement hideSurvey={props?.hideSurvey || false} />
              </ReportEditDialog>
              {AnalysisElement && (
                <ReportEditDialog
                  currentReportId={currentReportId}
                  isOpen={openAnalysisModal}
                  reportList={props.reportList}
                  reportResource={props.reportResource}
                  reportType={reportType}
                  setCurrentReportId={setCurrentReportId}
                  setIsOpen={setOpenAnalysisModal}
                  title="Configure Analysis"
                >
                  <AnalysisElement
                    subscription={subscription}
                    source={props.source}
                  />
                </ReportEditDialog>
              )}
              <DeleteButton
                resource="reports"
                // onSubmit={() => handleReportSelection()}
                record={{ id: currentReportId ?? 0 }}
              />
              {source === 'survey-report' && (
                <CopyReportsDialog setCopyLoader={setCopyLoader} />
              )}
            </>
          ) : null}
          {props?.CreateElement ? (
            <WithRecord
              render={(record) => (
                <CreateInDialogButton
                  ButtonProps={{
                    'aria-label': `Create ${props.reportLabel}`,
                  }}
                  record={{
                    project_id: record.id,
                    survey_id: record.id,
                  }}
                  fullWidth
                  sx={{
                    '& .MuiDialog-paper': {
                      maxHeight: 'calc(100vh - 165px)',
                    },
                  }}
                  resource={props.reportResource}
                  /* c8 ignore next 9 */
                  label={props.customLabel || `Create ${props.reportLabel}`}
                  mutationOptions={handleMutations}
                >
                  {props.CreateElement && <props.CreateElement />}
                </CreateInDialogButton>
              )}
            />
          ) : null}
          {isFromProject && hasReport ? (
            <Button
              aria-label="Project Report Configure"
              label="Configure"
              variant="text"
              onClick={() => setOpenProjectConfigure(true)}
              data-testid="configure-open-btn"
            >
              <Settings />
            </Button>
          ) : null}
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12 }}
        flex={1}
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: { xs: 'start', sm: 'end' },
        }}
      >
        <ReportingDatasetSelect
          datasets={getDatasets()?.datasets || {}}
          currentDataset={props.currentDataset}
          setCurrentDataset={props.setCurrentDataset}
          shouldShowLoading={getDatasets()?.shouldShowLoading || false}
        />
        <CrossTabPageSelect
          pageData={pageData}
          crossTabPage={currentPage}
          setCrossTabPage={setCurrentPage}
        />
      </Grid2>

      <Grid2 size={{ xs: 12 }} paddingTop={2}>
        {hasReport ? (
          <ReportElement
            isInsightSurvey
            source="cross_tab"
            crossTabPage={currentPage}
            reportId={currentReportId ?? 0}
            dataset={props.currentDataset}
            setPageData={setPageData}
            setCrossTabPage={setCurrentPage}
          />
        ) : null}
      </Grid2>
      <ProjectConfigureDialog
        open={openProjectConfigure}
        onClose={/* c8 ignore next 1 */ () => setOpenProjectConfigure(false)}
      />
      <Backdrop
        open={copyLoader}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid2>
  )
}

export default CommonReportNavigator
