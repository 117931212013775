import { Dialog } from '@mui/material'
import { useRefresh } from 'react-admin'
import ReportConfigure from '../pages/Reports/components/ReportConfigure'

interface ProjectConfigureDialogProps {
  open: boolean
  onClose: () => void
}

const ProjectConfigureDialog = ({
  open,
  onClose,
}: ProjectConfigureDialogProps) => {
  const refresh = useRefresh()

  /* c8 ignore next 4 */
  const handleClose = () => {
    onClose()
    refresh()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': { width: '90%', margin: 0 },
      }}
    >
      <ReportConfigure />
    </Dialog>
  )
}

export default ProjectConfigureDialog
