import * as Sentry from '@sentry/react'
import { Identifier, RaRecord } from 'ra-core'
import ReactGA from 'react-ga4'
import { Notify } from './types'

function isGreaterThanOneDay(previousTime: Date, nextTime: Date) {
  const previousDate = new Date(previousTime)
  const nextDate = new Date(nextTime)

  if (previousTime > nextTime) {
    return false
  }

  const oneDay = 24 * 60 * 60 * 1000 // hours * minutes * seconds * milliseconds
  const difference = nextDate.getTime() - previousDate.getTime()

  return difference >= oneDay
}

/* c8 ignore next 8 */
const resolveImageUrl = (path: string): string => {
  const protocol = import.meta.env.VITE_MX8_ADMIN_UI_PROTOCOL ?? 'https'
  const domain = import.meta.env.VITE_MX8_ADMIN_UI_DOMAIN
  const baseUrl = `${protocol}://${domain}`
  const resolvedUrl = new URL(path, baseUrl.toString())
  return resolvedUrl.toString()
}

const successHandler =
  (
    item: string,
    verb: string,
    redirect_path: string,
    notify: Notify,
    redirect?: (type: string, url: string, id?: number | string) => void,
    refresh?: () => void,
    refetch?: () => void,
  ) =>
  (data: RaRecord<Identifier>) => {
    /* c8 ignore next 14 */
    if (refresh) {
      refresh()
    }
    if (refetch) {
      refetch()
    }
    ReactGA.send({
      survey_name: data.name,
      status: 'success',
      survey_id: data.id,
    })
    notify(`${item} ${data.name ? data.name : ''} ${verb} successfully`, {
      type: 'success',
      undoable: false,
    })
    const surveyRedirect = () => {
      if (item == 'Survey') {
        return 'projects'
      } else if (item == 'Audience') {
        return `surveys/${data.survey_id}`
      } else {
        return `${item}s/${data.id}`
      }
    }
    /* c8 ignore next */
    if (!redirect) return
    switch (redirect_path) {
      case 'edit':
        redirect('edit', surveyRedirect()?.toLowerCase())
        break
      /* c8 ignore next 3 */
      case 'show':
        redirect('show', item.toLowerCase() + 's', data.id)
        break
      default:
        redirect('list', surveyRedirect()?.toLowerCase())
        break
    }
  }

const errorHandler =
  (item: string, verb: string, notify: Notify) => (error: unknown) => {
    Sentry.captureMessage(`${item} ${verb} failed: ${error}`, 'info')
    notify(`${item} ${verb} failed: ${error}`, {
      type: 'error',
      undoable: false,
      autoHideDuration: undefined,
    })
  }

export { errorHandler, isGreaterThanOneDay, resolveImageUrl, successHandler }
