import { IconButton, Tooltip } from '@mui/material'
import { useInput, useRecordContext } from 'react-admin'
import { SurveyEditButton } from '../../../Edit/styles'
import LockSvgIcone from './svgIcons/LockSvgIcone'
import UnLockSvgIcone from './svgIcons/UnLockSvgIcone'

const CodeLockUnlock = ({
  source,
  disabled,
  codeReadOnly,
  isCodeLock,
}: {
  source: string
  disabled: boolean
  codeReadOnly: () => void
  isCodeLock?: boolean
}) => {
  const surveyData = useRecordContext()
  const {
    field: { value, onChange },
  } = useInput({ source, defaultValue: surveyData?.survey_code_is_locked })
  return (
    <Tooltip title={value ? 'Unlock Code' : 'Lock Code'}>
      <IconButton
        sx={SurveyEditButton(isCodeLock)}
        onClick={() => {
          onChange(!value)
          codeReadOnly()
        }}
        disabled={disabled}
      >
        {value ? (
          <LockSvgIcone disabled={disabled} />
        ) : (
          <UnLockSvgIcone disabled={disabled} />
        )}
      </IconButton>
    </Tooltip>
  )
}

export default CodeLockUnlock
