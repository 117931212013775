import React, { useCallback, useEffect } from 'react'
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'
import { Box, useMediaQuery } from '@mui/material'
import debounce from 'lodash/debounce'
import { getApi, postApi } from './ReportApi'
import { dataGridContainer } from './styles'
import { CommonProps, GridState, SaveResponse } from '../../../../types'
import * as Sentry from '@sentry/react'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { CustomToolbar } from '../TableCustomToolBar/DataTableCustomToolbar'

const DataTableGrid: React.FC<CommonProps> = ({
  rows,
  columns,
  columnGroupingModel,
  rowGroupingModel,

  setRowGroupingModel,
  pinnedColumnsData,
  reportId,
  dataset,
  source,
  type,
}) => {
  const apiRef =
    /* NOSONAR */ useGridApiRef() as React.MutableRefObject<GridApiPremium>
  const resposiveUITablet = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    /* c8 ignore next 17 */
    const fetchGridState = async () => {
      try {
        const data = await getApi<GridState>(
          `/v1/reports/${dataset}/${reportId}`,
        )
        if (data?.grid_state && apiRef?.current) {
          apiRef?.current?.restoreState(data?.grid_state)
        }
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error fetching grid state:', error)
      } finally {
      }
    }
    if (type !== 'report-popup') fetchGridState()
  }, [dataset, reportId, apiRef])

  const debouncedHandleStateChange = useCallback(
    debounce(async () => {
      /* c8 ignore next 12 */
      try {
        if (apiRef?.current && reportId && type !== 'report-popup') {
          const gridState = apiRef?.current?.exportState()
          await postApi<SaveResponse>(`/v1/reports/${reportId}/grid_state`, {
            grid_state: gridState,
          })
        }
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error saving grid state:', error)
        Sentry.captureException(error)
      }
    }, 2000),
    [apiRef, reportId],
  )

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['question'],
      },
      pinnedColumns: {
        /* c8 ignore next 4 */
        left: resposiveUITablet
          ? []
          : ['__row_group_by_columns_group__', ...pinnedColumnsData],
      },
    },
  })
  const ToolbarSlot = useCallback(
    () => <CustomToolbar source={source} />,
    [source],
  )
  // eslint-disable-next-line
  const commonRowClassName = (params: any) => {
    return params.row.response === ''
      ? 'total-row'
      : params.id.split('-question')[0]
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <DataGridPremium
        data-testid="Data-Grid-Premium"
        apiRef={apiRef}
        initialState={initialState}
        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        checkboxSelection={false}
        rowHeight={35}
        columnHeaderHeight={35}
        hideFooter
        slots={{ toolbar: ToolbarSlot }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        resizeThrottleMs={0}
        /* c8 ignore next 3 */
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        rowGroupingModel={rowGroupingModel}
        /* c8 ignore next 3 */
        // eslint-disable-next-line
        getRowClassName={(params: any) => commonRowClassName(params)}
        defaultGroupingExpansionDepth={-1}
        onStateChange={debouncedHandleStateChange}
        sx={dataGridContainer}
      />
    </Box>
  )
}

export default DataTableGrid
