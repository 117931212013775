import { Alert, Grid2 } from '@mui/material'
import React from 'react'

interface AlertMessageProps {
  show: boolean
  message: string
  severity: 'warning' | 'success' | 'error' | 'info'
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  show,
  message,
  severity,
}) => {
  if (!show) return null

  return (
    <Grid2 size={{ xs: 12 }}>
      <Alert severity={severity}>{message}</Alert>
    </Grid2>
  )
}
