import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

import {
  handleCancel,
  handleGeneratingCode,
  handleReplaceCode,
} from '../../../../components/CodeEditor/utils'
import { CopilotCompTypes } from '../../types'
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { CopilotCommonButton } from '../../../../components'
import CancelIcon from '@mui/icons-material/Cancel'
import { UpdateCodeSkeleton } from '../../../../components/Skeletons'
import { Editor } from '@monaco-editor/react'
import { editorWrapper } from './style'
const CopilotComp = (props: CopilotCompTypes) => {
  const theme = useTheme()
  const darkMode = theme?.palette?.mode === 'dark'
  const [requestedText, setRequestedText] = useState('')
  const {
    editorValue,
    surveyId,
    instanceMonaco,
    instanceEditor,
    setCopilotState,
    copilotState,
  } = props

  const textFieldRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (copilotState.showInput) {
      setTimeout(() => {
        /* c8 ignore next 1 */
        textFieldRef.current?.focus()
      }, 100)
    }
  }, [copilotState.showInput])

  return (
    <Box>
      <Typography sx={{ fontSize: 16, fontWeight: 600, marginBottom: 1 }}>
        Edit with Copilot
      </Typography>
      <TextField
        label="Describe your change"
        multiline
        fullWidth
        data-testid="describe-code-input"
        inputRef={textFieldRef}
        variant="outlined"
        rows={4}
        sx={{
          '& .MuiInputBase-input': {
            minHeight: '40px',
            resize: 'vertical',
          },
          '& .MuiFormLabel-root ': { backgroundColor: 'background.paper' },
        }}
        value={requestedText}
        /* c8 ignore start */
        onChange={(e) => setRequestedText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            if (!copilotState?.isGenerating && requestedText.trim() !== '') {
              handleGeneratingCode({
                editorValue,
                requestedText,
                surveyId,
                copilotState,
                setCopilotState,
                instanceEditor,
              })
            }
          }
          /* c8 ignore end */
        }}
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginY: 2 }}
      >
        <CopilotCommonButton
          title={'Generate'}
          /* c8 ignore next 10 */
          onClick={() => {
            handleGeneratingCode({
              editorValue,
              requestedText,
              surveyId,
              copilotState,
              setCopilotState,
              instanceEditor,
            })
          }}
          disabled={copilotState?.isGenerating || requestedText == ''}
          Icone={<AutoFixHighIcon />}
        />
      </Box>

      {copilotState?.isGenerating ? (
        <UpdateCodeSkeleton />
      ) : (
        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
          <InputLabel sx={{ backgroundColor: 'background.paper' }} shrink>
            Updated Code
          </InputLabel>
          <Box p={1} sx={editorWrapper}>
            <Editor
              defaultValue={`\nDescribe your change and click "Generate" to see\nthe updated code here...`}
              height="180px"
              language="markdown"
              value={copilotState?.updatedCode}
              /* c8 ignore next 1 */
              theme={darkMode ? 'mx8-dark' : 'mx8'}
              options={{
                minimap: { enabled: false },
                lineNumbers: 'off',
                scrollbar: {
                  useShadows: false,
                  vertical: 'visible',
                  horizontal: 'visible',
                  horizontalScrollbarSize: 5,
                  verticalScrollbarSize: 5,
                },
                renderLineHighlight: 'none',
                readOnly: true,
              }}
            />
          </Box>
        </FormControl>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
          marginTop: 3,
        }}
      >
        <CopilotCommonButton
          title={'Apply'}
          onClick={() => {
            handleReplaceCode({
              setCopilotState,
              instanceEditor,
              editorValue,
              requestedText,
              instanceMonaco,
              surveyId,
              copilotState,
            })
          }}
          disabled={
            copilotState?.isGenerating || copilotState?.updatedCode == ''
          }
          Icone={<KeyboardDoubleArrowLeftIcon />}
        />
        <CopilotCommonButton
          onClick={() => {
            handleCancel({ setCopilotState, setRequestedText })
          }}
          title={'Cancel'}
          Icone={<CancelIcon />}
        />
      </Box>
    </Box>
  )
}

export default CopilotComp
