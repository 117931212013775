type AudienceStatus =
  | 'Updating'
  | 'New'
  | 'In Field'
  | 'Paused'
  | 'Complete'
  | 'Error'

const commonForbiddenFields = ['project_id', 'survey_id', 'language', 'type']

const statusBasedForbiddenFields: Record<AudienceStatus, string[]> = {
  Updating: [
    ...commonForbiddenFields,
    'weight',
    'weighting_audience_id',
    'status',
    'target_responses',
    'days_in_field',
    'json_audience',
    'excel_sheet',
    'contacts',
    'respondent_id_field',
    'urls',
    'questions_list',
    'price',
    'market',
  ],
  New: [...commonForbiddenFields, 'market'],
  Error: [...commonForbiddenFields, 'market'],
  'In Field': [
    ...commonForbiddenFields,
    'weight',
    'weighting_audience_id',
    'status',
    'target_responses',
    'dataset_id',
    'days_in_field',
    'questions_list',
    'market',
  ],
  Paused: [...commonForbiddenFields, 'status', 'dataset_id', 'market'],
  Complete: [
    ...commonForbiddenFields,
    'status',
    'target_responses',
    'days_in_field',
    'json_audience',
    'excel_sheet',
    'contacts',
    'respondent_id_field',
    'urls',
    'questions_list',
    'price',
    'dataset_id',
    'market',
  ],
}

export function canUpdateField(status: AudienceStatus, field: string): boolean {
  const forbiddenFields = statusBasedForbiddenFields[status] || []

  return forbiddenFields.includes(field)
}
