import { TableHead, TableRow, TableCell } from '@mui/material'
import { CopyReportsDialogStyles } from './style'

export const ReportsTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox"></TableCell>
      <TableCell sx={CopyReportsDialogStyles.tableCell}>Name</TableCell>
      <TableCell sx={CopyReportsDialogStyles.tableCell}>
        Matching Questions
      </TableCell>
      <TableCell sx={CopyReportsDialogStyles.tableCell}>Status</TableCell>
    </TableRow>
  </TableHead>
)
