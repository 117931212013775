import { useContext, useEffect, useState } from 'react'
import {
  EditInDialogButton,
  FormDialogContext,
} from '@react-admin/ra-form-layout'
import {
  SelectInput,
  Button,
  Toolbar,
  SimpleForm,
  useGetList,
  useDataProvider,
  useRecordContext,
  RaRecord,
  Identifier,
  useRefresh,
  useNotify,
} from 'react-admin'
import {
  Box,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material'
import { LibraryAdd } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ClearIcon from '@mui/icons-material/Clear'
import { CopyReportsDialogStyles, statusIconsStyles } from './style'
import { CopyableReport, CustomToolbarProps, Status } from './types'
import useCopyableReports from '../hooks/useCopyableReports'
import { ReportsTable } from './ReportsTable'

export const statusIcons: Record<Status, JSX.Element> = {
  full: <CheckIcon style={statusIconsStyles.full} />,
  partial: <QuestionMarkIcon style={statusIconsStyles.partial} />,
  failed: <ClearIcon style={statusIconsStyles.failed} />,
}

export const CustomToolbar = (props: CustomToolbarProps) => {
  const {
    setSourceSurvey,
    setCheckedReports,
    handleCopy,
    checkedReports,
    ...toolbarProps
  } = props
  const formDialogContext = useContext(FormDialogContext)

  /* c8 ignore next 8 */
  const close = formDialogContext ? formDialogContext.close : () => null
  const handleButtonClick = (shouldCopy = false) => {
    if (shouldCopy) handleCopy()
    setSourceSurvey('')
    setCheckedReports([])
    close()
  }

  return (
    <Toolbar {...toolbarProps} sx={CopyReportsDialogStyles.toolbar}>
      <Button
        label="Cancel"
        onClick={/* c8 ignore next 1 */ () => handleButtonClick()}
        variant="text"
        sx={CopyReportsDialogStyles.cancelButton}
      />
      <Button
        label="Copy"
        onClick={/* c8 ignore next 1 */ () => handleButtonClick(true)}
        disabled={!checkedReports.length}
        variant="text"
        sx={CopyReportsDialogStyles.copyButton}
      />
    </Toolbar>
  )
}

const CopyReportsDialog = ({
  setCopyLoader,
}: {
  setCopyLoader: (loading: boolean) => void
}) => {
  /* c8 ignore next 2 */
  const { data: surveys = [] } = useGetList('surveys') || {}
  const [sourceSurvey, setSourceSurvey] = useState<string>('')
  const [checkedReports, setCheckedReports] = useState<CopyableReport[]>([])
  const record = useRecordContext()
  const targetSurveyId = record?.id
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const formDialogContext = useContext(FormDialogContext)

  /* c8 ignore next 22 */
  const handleCopy = async () => {
    try {
      const API_BASE_URL = `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}`
      setCopyLoader(true)
      await dataProvider.updateCopyList(
        `${API_BASE_URL}/v1/surveys/${sourceSurvey}/copy_reports/${targetSurveyId}`,
        checkedReports,
      )
      setCheckedReports([])
      setSourceSurvey('')
      refresh()
      formDialogContext?.close()
      notify('Reports copied successfully', { type: 'success' })
    } catch (error) {
      notify(error instanceof Error ? error.message : 'Error copying reports', {
        type: 'error',
      })
    } finally {
      setCopyLoader(false)
    }
  }

  const {
    data: copyableReports,
    loading,
    fetchReports,
  } = useCopyableReports(sourceSurvey?.toString(), targetSurveyId?.toString())

  useEffect(() => {
    fetchReports()
  }, [sourceSurvey])

  /* c8 ignore next 4 */
  const handleSurveyChange = (
    event: RaRecord<Identifier> | React.ChangeEvent<HTMLInputElement>,
  ) => setSourceSurvey(event.target.value)

  /* c8 ignore next 12 */
  const handleCheckboxChange = (report: CopyableReport) => {
    setCheckedReports((prev) => {
      const isReportChecked = prev.some((r) => r.report_id === report.report_id)
      if (isReportChecked) {
        return prev.filter((r) => r.report_id !== report.report_id)
      } else {
        return [...prev, report]
      }
    })
  }

  return (
    <EditInDialogButton
      label="Copy"
      icon={<LibraryAdd />}
      sx={CopyReportsDialogStyles.dialog}
      maxWidth="md"
      hideBackdrop
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            setSourceSurvey={setSourceSurvey}
            setCheckedReports={setCheckedReports}
            checkedReports={checkedReports}
            handleCopy={handleCopy}
          />
        }
      >
        <Box p={3} width={'100%'} sx={CopyReportsDialogStyles.container}>
          <Typography gutterBottom sx={CopyReportsDialogStyles.title}>
            Copy Reports from Another Survey
          </Typography>

          <SelectInput
            id="source-survey-input"
            source="sourceSurvey"
            label="Source Survey"
            choices={surveys}
            fullWidth
            onChange={handleSurveyChange}
          />

          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <ReportsTable
                sourceSurvey={sourceSurvey}
                copyableReports={copyableReports}
                checkedReports={checkedReports}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
          </TableContainer>
        </Box>
      </SimpleForm>
    </EditInDialogButton>
  )
}

export default CopyReportsDialog
