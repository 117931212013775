import { useDataProvider } from 'ra-core'
import { useState } from 'react'
import { CopyableReport } from 'src/components/types'
import { fetchReports } from './fetchReports'

const useCopyableReports = (
  sourceSurveyId?: string,
  targetSurveyId?: string,
) => {
  const dataProvider = useDataProvider()
  const [data, setData] = useState<CopyableReport[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const fetchReportsWrapper = async () => {
    setLoading(true)
    try {
      const reports = await fetchReports(
        dataProvider,
        sourceSurveyId,
        targetSurveyId,
      )
      setData(reports)
      setError(undefined)
      /* c8 ignore next 4 */
    } catch (err) {
      setError(err as Error)
    }
    setLoading(false)
  }

  return { data, loading, error, fetchReports: fetchReportsWrapper }
}

export default useCopyableReports
