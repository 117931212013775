import { FC } from 'react'
import { ReportingDatasetSelectProps } from '../pages/Reports/types'
import { Labeled } from 'react-admin'
import { MenuItem, Select, Skeleton } from '@mui/material'

const ReportingDatasetSelect: FC<ReportingDatasetSelectProps> = (props) => {
  const { datasets, currentDataset, setCurrentDataset, shouldShowLoading } =
    props

  const id_valid =
    !!currentDataset && datasets[currentDataset] && !shouldShowLoading

  const validValue = id_valid ? currentDataset : '0'

  return (
    <Labeled label="Dataset">
      <Select
        id="dataset-selector"
        fullWidth
        value={validValue}
        name="Dataset Selector"
        size="small"
        onChange={(event) => {
          setCurrentDataset(event.target.value)
        }}
        disabled={Object.keys(datasets).length == 0}
      >
        {id_valid ? (
          Object.entries(datasets).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))
        ) : (
          <MenuItem key="0" value="0">
            <Skeleton title="dataset-Skeleton" width={75} height={25} />
          </MenuItem>
        )}
      </Select>
    </Labeled>
  )
}

export default ReportingDatasetSelect
