const UnLockSvgIcone = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 330 330"
      data-testid="unlock-icon"
    >
      <path
        d="M15,160c8.284,0,15-6.716,15-15V85c0-30.327,24.673-55,55-55c30.327,0,55,24.673,55,55v45h-25
                         c-8.284,0-15,6.716-15,15v170c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15H170V85
                         c0-46.869-38.131-85-85-85S0,38.131,0,85v60C0,153.284,6.716,160,15,160z"
        fill={disabled ? '#A9A29C' : '#317CFE'}
      />
    </svg>
  )
}

export default UnLockSvgIcone
