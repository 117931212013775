import { TableRow, TableCell, Checkbox } from '@mui/material'
import { CopyableReport } from './types'
import { CopyReportsDialogStyles } from './style'
import { statusIcons } from './CopyReportsDialog'

interface ReportsTableRowProps {
  report: CopyableReport
  checkedReports: CopyableReport[]
  handleCheckboxChange: (report: CopyableReport) => void
}

export const ReportsTableRow = ({
  report,
  checkedReports,
  handleCheckboxChange,
}: ReportsTableRowProps) => (
  <TableRow key={report.report_id} sx={CopyReportsDialogStyles.tableRow}>
    <TableCell padding="checkbox">
      <Checkbox
        checked={checkedReports.some((r) => r.report_id === report.report_id)}
        onChange={() => handleCheckboxChange(report)}
        disabled={!report.copy_report}
        icon={<span style={CopyReportsDialogStyles.checkboxIcon} />}
        checkedIcon={
          <span
            style={CopyReportsDialogStyles.checkedIcon as React.CSSProperties}
          >
            <span
              style={CopyReportsDialogStyles.checkMark as React.CSSProperties}
            />
          </span>
        }
      />
    </TableCell>
    <TableCell
      sx={CopyReportsDialogStyles.tableCellContent(report.copy_report)}
      aria-disabled={!report.copy_report}
    >
      {report.name}
    </TableCell>
    <TableCell aria-disabled={!report.copy_report}>
      {`${report.matching_questions}/${report.total_questions}`}
    </TableCell>
    <TableCell aria-disabled={!report.copy_report}>
      {statusIcons[report.status]}
    </TableCell>
  </TableRow>
)
