import { Skeleton, Box, Typography, Card, CardContent } from '@mui/material'
import { Mx8Theme } from '../../../layout'
const Translationskeleton = () => {
  return (
    <Card variant="outlined" sx={{ width: '100%', marginTop: 1 }}>
      <CardContent sx={{ width: '100%' }}>
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              fontSize: 16,
              color: Mx8Theme.palette.primary.main,
            }}
          >
            Questions
          </Typography>
          <Skeleton variant="text" width={'100%'} height={60} />
          <Skeleton variant="text" width={'100%'} height={60} />

          <Skeleton
            variant="text"
            sx={{ marginLeft: 10 }}
            width={'95%'}
            height={60}
          />
          <Skeleton
            variant="text"
            sx={{ marginLeft: 10 }}
            width={'95%'}
            height={60}
          />
          <Skeleton
            variant="text"
            sx={{ marginLeft: 10 }}
            width={'95%'}
            height={60}
          />
          <Skeleton variant="text" width={'100%'} height={60} />
          <Skeleton variant="text" width={'100%'} height={60} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default Translationskeleton
