import { Table, TableBody, Typography } from '@mui/material'
import { CopyableReport } from './types'
import { ReportsTableHeader } from './ReportsTableHeader'
import { ReportsTableRow } from './ReportsTableRow'

interface ReportsTableProps {
  sourceSurvey: string
  copyableReports: CopyableReport[]
  checkedReports: CopyableReport[]
  handleCheckboxChange: (report: CopyableReport) => void
}

export const NoReportsMessage = ({
  sourceSurvey,
  copyableReports,
}: {
  sourceSurvey: string
  copyableReports: CopyableReport[]
}) => {
  if (sourceSurvey && copyableReports?.length === 0) {
    return (
      <Typography color="red" fontSize={'.85rem'}>
        No reports to copy
      </Typography>
    )
  }
  return null
}

export const ReportsTable = ({
  sourceSurvey,
  copyableReports,
  checkedReports,
  handleCheckboxChange,
}: ReportsTableProps) => {
  if (!sourceSurvey || !copyableReports?.length) {
    return null
  }

  return (
    <>
      <Table>
        <ReportsTableHeader />
        <TableBody>
          {copyableReports.map((report) => (
            <ReportsTableRow
              key={report.report_id}
              report={report}
              checkedReports={checkedReports}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </TableBody>
      </Table>
      <NoReportsMessage
        sourceSurvey={sourceSurvey}
        copyableReports={copyableReports}
      />
    </>
  )
}
