import { SxProps } from '@mui/material'
import { Mx8Theme } from '../layout'
import { Status, TableResizeObjectInterface } from './types'

// Remove JSX imports and statusIcons constant, only export the type
export type StatusIconsType = Record<
  Status,
  {
    color: string
    width: number
    height: number
  }
>

export const statusIconsStyles: StatusIconsType = {
  full: { color: 'green', width: 20, height: 20 },
  partial: { color: '#3880FE', width: 20, height: 20 },
  failed: { color: 'red', width: 20, height: 20 },
}

export const CreateButtonStyle = {
  width: { xs: '38%' },
  fontSize: { sm: '15px', xs: '13px' },
  '&  .MuiButton-text': {
    fontSize: '13px',
  },
}
export const commonSearchAndCreateButton = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: { xs: 1 },
}

export const commonSearahInputStyle = {
  '& .MuiInputBase-input': { height: '1rem' },
}
export const CommonSaveCancelButton = {
  '& .MuiToolbar-gutters .RaToolbar-defaultToolbar': {
    position: 'fixed',
    right: '1.2%',
    gap: 2,
  },
  '& .MuiToolbar-gutters': {
    position: 'fixed',
    top: '93%',
    width: '100%',
  },
}

export const CustomFormActionContainer = (isDialog: boolean): SxProps => ({
  zIndex: '100 !important',
  justifyContent: 'end',
  gap: '15px',
  ...(!isDialog
    ? {
        position: 'fixed',
        right: 0,
        left: 0,
        bottom: 0,
        boxShadow: 'none',
        backgroundColor: 'background.paper',
        borderTop: `1px solid ${Mx8Theme.palette.divider}`,
      }
    : {
        backgroundColor: 'divider',
      }),
})

export const CommonNameText = {
  fontSize: '13px',
  fontWeight: '500',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  width: '98%',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}

export const CommonInputStyle = {
  ' & .MuiOutlinedInput-input': { fontSize: 14, padding: '7px 0px 7px 12px' },
  '& .MuiFormHelperText-root': { display: 'none' },
  '& .MuiFormLabel-root ': { fontSize: 13 },
}

export const TableResizeObject: TableResizeObjectInterface = {
  isScreenBelow400Value: {
    calculateHeightsInights: `calc(155vh - 208px)`,
    calculateHeightList: `calc(82.2vh - 155px)`,
  },
  isScreenBetween400And600Value: {
    calculateHeightsInights: `calc(117vh - 208px)`,
    calculateHeightList: `calc(62.1vh - 155px)`,
  },
  isScreenAbove600Value: {
    calculateHeightsInights: `calc(96.4vh - 208px)`,
    calculateHeightList: `calc(50vh - 160px)`,
  },
}

export const tableColumn = (height?: string): SxProps => ({
  '& .RaDatagrid-tableWrapper': {
    ...(height ? { height, overflow: 'auto' } : {}),
    '& .MuiTableCell-root': {
      '&:not(:nth-of-type(1),:nth-of-type(2))': {
        textAlign: 'center',
      },
    },
  },
})

export const CopyReportsDialogStyles = {
  dialog: {
    '& .MuiDialogTitle-root': { display: 'none' },
    '& .MuiCardContent-root': { padding: '0px !important' },
    border: '3px solid #000',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: '0px 1px 1px 1px',
  },
  cancelButton: {
    color: '#000',
    padding: '.3rem 1.5rem',
    border: '1px solid #000',
    marginRight: '1rem',
  },
  copyButton: {
    color: '#000',
    padding: '.3rem 2rem',
    border: '1px solid #000',
  },
  container: {
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: '1px 1px 0px 1px',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 2,
  },
  tableCell: {
    fontWeight: 900,
  },
  tableRow: {
    color: '#afafaf',
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: '#fffefa',
    },
  },
  checkboxIcon: {
    display: 'inline-block',
    width: '18px',
    height: '18px',
    border: '2px solid #000',
    borderRadius: '3px',
  },
  checkedIcon: {
    display: 'inline-block',
    width: '18px',
    height: '18px',
    backgroundColor: '#fffefa',
    border: '2px solid #000',
    borderRadius: '3px',
    position: 'relative',
  },
  checkMark: {
    display: 'block',
    width: '6px',
    height: '12px',
    border: 'solid #000',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transformOrigin: 'center',
    translate: '-50% -60%',
    backgroundColor: '#fffefa',
  },
  tableCellContent: (copyReport: boolean) => ({
    color: /* c8 ignore next 1 */ copyReport ? '#000' : '#afafaf',
    fontWeight: 500,
  }),
}

export const CopilotCommonButtonStyle = {
  color: 'black',
  border: '1px solid black',
  borderRadius: '0px',
  marginY: 2,
  display: 'flex',
  justifySelf: 'flex-end',
}
