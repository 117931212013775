import { Box, Grid2, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import {
  Button,
  Labeled,
  RaRecord,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
  useStore,
  WithRecord,
} from 'react-admin'
import { useEffect, useState } from 'react'
import { Analytics, Create, Settings } from '@mui/icons-material'
import ReportEditDialog from '../pages/Reports/components/ReportEditDialog'
import { CrossTabPager, RecordType } from '../utils/types'
import { ReportingDatasetSelect, CrossTabPageSelect } from '.'
import { NavigationProps } from './types'
import ProjectConfigureDialog from './ProjectConfigureDialog'
import { useLocation, useNavigate } from 'react-router'

const CommonInsightNavigator = (props: NavigationProps) => {
  const {
    source,
    ReportElement,
    subscription = undefined,
    currentReportId,
    setCurrentReportId,
    setCurrentDataset,
  } = props
  const refresh = useRefresh()
  const noInsight = `No ${props.reportType}s available`
  const reports_list = props.reportList
  const [editInDialogOpen, setEditInDialogOpen] = useState(false)
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false)
  const [openProjectConfigure, setOpenProjectConfigure] = useState(false)
  const [isManualChange, setIsManualChange] = useState(false)
  const notify = useNotify()
  const location = useLocation()
  const navigate = useNavigate()
  const record = useRecordContext()

  /* c8 ignore next 7 */
  const [currentPage, setCurrentPage] = useStore(
    `crossTabPage-${props.reportType}-${props.uniqueId}`,
  )
  const [currentInsightDataset] = useStore(
    `current-survey-insight-${record?.id}`,
  )
  const [pageData, setPageData] = useState<CrossTabPager>()
  const isInsightTab = location.pathname.includes('insights')
  const [latestInsightId] = useStore(`current-survey-insight-${record?.id}`)
  const [latestProjectInsightId] = useStore(
    `current-project-insight-${record?.id}`,
  )
  /* c8 ignore next 8 */
  const [selectedInsightNumber, selectedInsightType] = location.hash
    ? location.hash.substring(1).split('/')
    : [null, null]
  const hasInsightReport =
    reports_list?.length && reports_list?.length > 0 && !props.disabled
  const dynamicReportId =
    source === 'project-report' ? latestProjectInsightId : latestInsightId
  const { data: reportInsightDataSetData } = useGetOne(
    `insights/${props.currentDataset}`,
    {
      id: currentReportId,
    },
    {
      enabled:
        !!currentReportId &&
        isInsightTab &&
        !!props.currentDataset &&
        reports_list &&
        reports_list?.length > 0,
    },
  )

  useEffect(() => {
    if (isInsightTab) {
      /* c8 ignore next 15 */
      if (!hasInsightReport) {
        handleNoReport()
      } else if (
        reports_list &&
        reports_list?.length > 0 &&
        !isManualChange &&
        !(selectedInsightNumber && selectedInsightType)
      ) {
        handleReportList()
      } else if (selectedInsightNumber && selectedInsightType) {
        handleSelectedInsight()
      } else if (currentReportId && props.currentDataset) {
        handleExistingParams()
      }
    }
    setIsManualChange(false)
  }, [
    reports_list,
    dynamicReportId,
    selectedInsightNumber,
    selectedInsightType,
    isInsightTab,
    hasInsightReport,
  ])

  useEffect(() => {
    if (isInsightTab && selectedInsightNumber && selectedInsightType) {
      setIsManualChange(true)
      handleSelectedInsight()
    }
  }, [selectedInsightNumber, selectedInsightType])

  const handleNoReport = () => {
    navigate(location.pathname, { replace: true })
  }

  /* c8 ignore next 17 */
  const handleReportList = () => {
    const initialDataset = 'live'
    const activeReport = dynamicReportId
      ? reports_list?.find((report: RaRecord) => report?.id === dynamicReportId)
      : reports_list?.[0]
    const availableDatasets = activeReport?.datasets ?? {}
    const hasLiveData = Object.keys(availableDatasets).includes(initialDataset)
    const targetDataset = hasLiveData
      ? initialDataset
      : (Object.keys(availableDatasets)[0] ?? initialDataset)
    navigate(`#${activeReport?.id}/${targetDataset}`, {
      replace: true,
    })
    setCurrentReportId(parseInt(activeReport?.id ?? dynamicReportId))
    setCurrentDataset(targetDataset)
  }

  const handleSelectedInsight = () => {
    if (selectedInsightNumber && selectedInsightType) {
      const isNumber = !!parseInt(selectedInsightType)
      /* c8 ignore next 2 */
      const dataset = isNumber ? currentInsightDataset : selectedInsightType

      const inReport = reports_list?.find(
        (report: RaRecord) => report.id === parseInt(selectedInsightNumber),
      )
      /* c8 ignore next 16 */
      if (inReport) {
        navigate(`#${selectedInsightNumber}/${dataset}`, {
          replace: true,
        })
        setCurrentReportId(parseInt(selectedInsightNumber))
        setCurrentDataset(dataset)
      } else if (latestInsightId) {
        navigate(`#${latestInsightId}/${props.currentDataset}`, {
          replace: true,
        })
        setCurrentReportId(parseInt(latestInsightId))
        setCurrentDataset(props.currentDataset)
      } else {
        handleReportList()
      }
    }
  }

  /* c8 ignore next 6 */
  const handleExistingParams = () => {
    navigate(`#${currentReportId}/${props.currentDataset}`, {
      replace: true,
    })
  }

  /* c8 ignore next 18 */
  const isInsightDataLoading =
    reportInsightDataSetData?.insight_report?.status === 'pending' ||
    reportInsightDataSetData?.insight_report?.status === 'error'

  const getDatasets = () => {
    if (reportInsightDataSetData?.datasets) {
      return {
        datasets: reportInsightDataSetData.datasets,
        isInsightDataLoading,
      }
    }
    return { datasets: {}, isInsightDataLoading: false }
  }

  const getProjectId = (record: RecordType): string => {
    return record.project_id ?? record.id ?? ''
  }

  /* c8 ignore next 19 */
  const handleMutations = {
    onSuccess: (record: RaRecord) => {
      setIsManualChange(true)
      navigate(
        `#${parseInt(record.id.toString())}/${props.currentDataset ?? 'live'}`,
        {
          replace: true,
        },
      )
      setCurrentReportId(parseInt(record.id.toString()))
      notify(`${props?.reportType} created successfully`, {
        type: 'success',
        undoable: false,
      })
      refresh()
      props?.reportType == 'report' && setEditInDialogOpen(true)
    },
  }
  const isFromProject = props.uniqueId.includes('project')
  const EditElement = props.EditElement
  const AnalysisElement = props.AnalysisElement

  /* c8 ignore next 7 */
  const handleInsightChange = (event: SelectChangeEvent<number>) => {
    setIsManualChange(true)
    navigate(`#${event.target.value}/${props.currentDataset}`, {
      replace: true,
    })
    setCurrentReportId(parseInt(event.target.value.toString()))
  }

  return (
    <Grid2 width="100%" container spacing={2}>
      <Grid2 size={{ md: 5, xs: 12 }}>
        <Labeled fullWidth>
          {hasInsightReport ? (
            <Select
              data-testid={`${props.reportType}-selector`}
              size="small"
              fullWidth
              label={props.reportLabel}
              value={currentReportId ?? dynamicReportId ?? ''}
              onChange={handleInsightChange}
            >
              {reports_list.map((insight: RaRecord) => {
                return (
                  <MenuItem
                    data-testid={`${props.reportType}-selector-${insight.id}`}
                    key={insight.id}
                    value={insight.id}
                  >
                    {insight[props.reportNameField]}
                  </MenuItem>
                )
              })}
            </Select>
          ) : (
            <Select
              data-testid={`${props.reportType}-selector`}
              label={props.reportLabel}
              size="small"
              value={noInsight}
              fullWidth
              disabled
            >
              <MenuItem
                key={noInsight}
                data-testid={`${props.reportType}-selector-${noInsight}`}
                value={noInsight}
              >
                {noInsight}
              </MenuItem>
            </Select>
          )}
        </Labeled>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ display: 'flex', justifyContent: 'start' }}
      >
        <Box
          display="flex"
          justifyContent="start"
          flexDirection="row"
          flexWrap={`wrap`}
          gap="5px"
          sx={{ marginTop: { sm: '20px', xs: null } }}
        >
          {hasInsightReport ? (
            <>
              <Button
                aria-label={`Edit ${props.reportType}`}
                /* c8 ignore next 3 */
                onClick={() => {
                  setEditInDialogOpen(true)
                }}
                label="Edit"
                variant="text"
              >
                <Create />
              </Button>

              {AnalysisElement && (
                <Button
                  aria-label={`Analysis ${props.reportType}`}
                  label="Analysis"
                  variant="text"
                  onClick={() => setOpenAnalysisModal(true)}
                  data-testid="analysis-open-btn"
                >
                  <Analytics />
                </Button>
              )}
              <ReportEditDialog
                currentReportId={currentReportId}
                isOpen={editInDialogOpen}
                reportList={props.reportList}
                reportResource={props.reportResource}
                reportType={props.reportType}
                setCurrentReportId={setCurrentReportId}
                setIsOpen={setEditInDialogOpen}
                title={'Edit Insights'}
                setAnalysisOpen={setOpenAnalysisModal}
              >
                <EditElement hideSurvey={props?.hideSurvey || false} />
              </ReportEditDialog>
              {AnalysisElement && (
                <ReportEditDialog
                  currentReportId={currentReportId}
                  isOpen={openAnalysisModal}
                  reportList={props.reportList}
                  reportResource={props.reportResource}
                  reportType={props.reportType}
                  setCurrentReportId={setCurrentReportId}
                  setIsOpen={setOpenAnalysisModal}
                  title="Configure Analysis"
                >
                  <AnalysisElement
                    subscription={subscription}
                    source={props.source}
                  />
                </ReportEditDialog>
              )}
            </>
          ) : null}
          {props?.CreateElement ? (
            <WithRecord
              render={(record) => (
                <CreateInDialogButton
                  ButtonProps={{
                    'aria-label': `Create ${props.reportLabel}`,
                  }}
                  record={{
                    project_id: getProjectId(record),
                    survey_id: record.id,
                  }}
                  fullWidth
                  sx={{
                    '& .MuiDialog-paper': {
                      maxHeight: 'calc(100vh - 165px)',
                    },
                  }}
                  resource={props.reportResource}
                  /* c8 ignore next 9 */
                  label={props.customLabel || `Create ${props.reportLabel}`}
                  mutationOptions={handleMutations}
                >
                  {props.CreateElement && <props.CreateElement />}
                </CreateInDialogButton>
              )}
            />
          ) : null}
          {
            /* c8 ignore next 12 */ isFromProject && hasInsightReport ? (
              <Button
                aria-label="Project Report Configure"
                label="Configure"
                variant="text"
                onClick={() => setOpenProjectConfigure(true)}
                data-testid="configure-open-btn"
              >
                <Settings />
              </Button>
            ) : null
          }
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12 }}
        flex={1}
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: { xs: 'start', sm: 'end' },
        }}
      >
        <ReportingDatasetSelect
          datasets={/* c8 ignore next 1 */ getDatasets()?.datasets || {}}
          currentDataset={props.currentDataset}
          setCurrentDataset={props.setCurrentDataset}
          shouldShowLoading={getDatasets()?.isInsightDataLoading || false}
        />
        <CrossTabPageSelect
          pageData={pageData}
          crossTabPage={currentPage}
          setCrossTabPage={setCurrentPage}
        />
      </Grid2>

      <Grid2 size={{ xs: 12 }} paddingTop={2}>
        {hasInsightReport ? (
          <ReportElement
            isInsightSurvey
            source="cross_tab"
            crossTabPage={currentPage}
            reportId={currentReportId ?? dynamicReportId}
            dataset={props.currentDataset}
            setPageData={setPageData}
            setCrossTabPage={setCurrentPage}
          />
        ) : null}
      </Grid2>
      <ProjectConfigureDialog
        open={openProjectConfigure}
        onClose={/* c8 ignore next 1 */ () => setOpenProjectConfigure(false)}
      />
    </Grid2>
  )
}

export default CommonInsightNavigator
