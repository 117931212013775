import { FC } from 'react'
import { EditDialog } from '@react-admin/ra-form-layout'
import { ResourceContextProvider, useRefresh } from 'ra-core'
import { SimpleForm } from 'react-admin'
import { FormActionContainer } from '../../../components/FormActionContainer'
import { useQueryClient } from '@tanstack/react-query'
import { ReportEditDialogProps } from '../types'

const ReportEditDialog: FC<ReportEditDialogProps> = (props) => {
  const {
    isOpen,
    reportResource,
    setIsOpen,
    setAnalysisOpen,
    currentReportId,
    setCurrentReportId,
    reportList,
    reportType,
    children,
    title,
  } = props
  const refresh = useRefresh()

  const queryClient = useQueryClient()

  /* c8 ignore next 9 */
  const handleClose = () => {
    setIsOpen(false)
    queryClient.removeQueries({
      queryKey: ['report_questions'],
    })
    queryClient.removeQueries({
      queryKey: ['report_topics'],
    })
  }

  return (
    <EditDialog
      sx={{
        '& .MuiToolbar-gutters .RaToolbar-defaultToolbar': {
          justifyContent: 'flex-end',
          gap: 2,
        },
        '& .MuiDialog-paper': {
          maxHeight: 'calc(100vh - 165px)',
        },
      }}
      close={handleClose}
      fullWidth
      isOpen={isOpen}
      maxWidth="lg"
      resource={reportResource}
      id={currentReportId}
      mutationMode="pessimistic"
      /* c8 ignore next 8 */
      mutationOptions={{
        onSuccess: () => {
          if (title === 'Report Questions' && setAnalysisOpen) {
            setAnalysisOpen(true)
          }
          setIsOpen(false)
          refresh()
        },
      }}
      title={title}
      keepMounted={true}
    >
      <ResourceContextProvider value={reportResource}>
        <SimpleForm
          toolbar={
            <FormActionContainer
              source={reportType}
              setEditInDialogOpen={setIsOpen}
              setCurrentReportId={setCurrentReportId}
              reports_list={reportList}
              currentReportId={currentReportId ?? undefined}
              editInDialogOpen={isOpen}
            />
          }
        >
          {children}
        </SimpleForm>
      </ResourceContextProvider>
    </EditDialog>
  )
}

export default ReportEditDialog
